import React from "react"

import Layout from "../../components/layout"
import SEO from "../../components/seo"
import Header from "../../components/header"
import Footer from "../../components/footer"
import ServeAds from "../../components/ads"
import config from "./_config"
import theme from "./_theme"

class Lander extends React.PureComponent {
  render() {

    return (
      <div className="global">
        <Header siteTitle={config.title} siteRoot={config.root} theme={theme} />
        <Layout siteOwner={config.owner}>
          <SEO title={config.title} />
          <div id="im-north"></div>
          <div className="im-results container">
            <p className="right im-more">More results...</p>
          </div>
          <div id="im-south"></div>
          <ServeAds parentId={config.parentId} template={config.template} />
        </Layout>
        <Footer siteOwner={config.owner} />
      </div>
    )
  }
}
export default Lander
